/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Typography, Slide, Dialog, DialogContent, DialogTitle, Grid, Button, Box, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dispatch } from 'store';
import { GetUserAllPdfApi, UploadResourceApi, openPopupModel } from 'store/slices/userDashboard';

const Transition = React.forwardRef((props, ref) => <Slide direction="top" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '10px',
        maxWidth: '450px',
        width: '100%',
        textAlign: 'center'
    },
    '& .MuDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

// Yup validation schema
const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    file: Yup.mixed().required('A file is required')
});

const UploadCommonModel = ({ open, close }) => {
    const fileRef = useRef(null);

    // Function to trigger the hidden file input
    const handleFileUploadClick = () => {
        fileRef.current.click();
    };

    // Function to close the dialog
    const handleClose = () => {
        dispatch(openPopupModel(false));
        dispatch(GetUserAllPdfApi(1, 10));
    };

    // Initialize Formik using the useFormik hook
    const formik = useFormik({
        initialValues: {
            title: '',
            file: null
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                // Create FormData and append required fields
                const formData = new FormData();
                formData.append('domain', 'pharmacy');
                formData.append('title', values.title);
                formData.append('file', values.file);

                // Dispatch the API call and await its response
                await dispatch(UploadResourceApi(formData));

                // If successful, reset the form and close the modal
                resetForm();
                handleClose();
            } catch (error) {
                console.error('Error uploading file:', error);
                // Optionally, you can add error handling here (e.g., display a notification)
            } finally {
                // Stop the loader
                setSubmitting(false);
            }
        }
    });

    return (
        <BootstrapDialog
            open={open}
            TransitionComponent={Transition}
            maxWidth="xl" // Set the desired max width here (e.g., "xs", "sm", "md", "lg", "xl")
            fullWidth // Ensures that the dialog takes up the full width available
        >
            <DialogTitle>
                <Typography variant="h2" align="center" fontWeight={600}>
                    Upload File
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h4" align="center" sx={{ mt: 3, color: '#757d7e', textAlign: 'center', fontWeight: 400 }}>
                    Upload your document to turn it into an interactive Document, Videos, and Decks
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ borderRadius: 2, p: 2, background: '#EEEEEE' }}>
                        <Box
                            onClick={handleFileUploadClick}
                            sx={{
                                background: '#ffffff',
                                border: '2px dashed #ccc',
                                borderRadius: '8px',
                                textAlign: 'center',
                                padding: '20px',
                                cursor: 'pointer'
                                // mb: 3
                            }}
                        >
                            <Typography variant="body1" color="primary">
                                {formik.values.file ? formik.values.file.name : 'Upload Research Document'}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileRef}
                        style={{ display: 'none' }}
                        onChange={(event) => {
                            formik.setFieldValue('file', event.currentTarget.files[0]);
                        }}
                    />
                    {formik.touched.file && formik.errors.file && (
                        <Typography variant="caption" color="error" display="block" mb={2}>
                            {formik.errors.file}
                        </Typography>
                    )}

                    <Box sx={{ borderRadius: 2, p: 2, background: '#EEEEEE', mt: 2 }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={3}>
                                <Typography component="legend" variant="p" color={'#75797E'} mt={1.5}>
                                    Title
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    background="#fff"
                                    ba
                                    hiddenLabel
                                    id="title"
                                    name="title"
                                    // label="Title"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ borderColor: '#EEEEEE' }}
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    // error={formik.touched.title && Boolean(formik.errors.title)}
                                    // helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {formik.touched.title && formik.errors.title && (
                        <Typography variant="caption" color="error" display="block" mb={2}>
                            {formik.errors.title}
                        </Typography>
                    )}

                    <Grid container spacing={2} justifyContent="center" mt={2}>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                                disabled={formik.isSubmitting}
                                sx={{
                                    color: '#a6acb1',
                                    borderRadius: '8px',
                                    // padding: '10px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    border: '2px solid #a6acb1',
                                    '&:hover': {
                                        color: '#1d1f20',
                                        border: '1px solid #1d1f20'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="submit"
                                variant="outlined"
                                fullWidth
                                color="primary"
                                disabled={formik.isSubmitting}
                                sx={{
                                    borderRadius: '8px',
                                    // padding: '10px',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    color: '#0131fd',
                                    border: '2px solid #0131fd'
                                }}
                            >
                                {formik.isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Upload'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </BootstrapDialog>
    );
};

UploadCommonModel.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};

export default UploadCommonModel;
