import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconBell, IconSearch } from '@tabler/icons-react';
import { dispatch } from 'store';
import { openPopupModel } from 'store/slices/userDashboard';
import { useSelector } from 'react-redux';
import UploadCommonModel from 'views/dashboard/Popup/UploadCommonModel';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const SearchCard = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { modalState } = useSelector((state) => state.userDashboard);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => setValue(event?.target.value);

    return (
        <>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Button
                    sx={{
                        backgroundColor: '#363636',
                        color: '#ffffff',
                        px: '20px',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: '#ffffff', // Inverse background color
                            color: '#363636', // Inverse text color
                            border: '2px solid #363636' // Add a border
                        }
                    }}
                    onClick={() => dispatch(openPopupModel(true))}
                >
                    UPLOAD
                </Button>
            </Box>
            {/* <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Box
                    sx={{
                        ml: 2,
                        [theme.breakpoints.down('lg')]: {
                            mr: 2
                        }
                    }}
                >
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'transparent',
                            // color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: '#363636',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        // onClick={handleToggle}
                        color="inherit"
                    >
                        <IconSearch stroke={1.5} size="20px" />
                    </Avatar>
                </Box>
            </Box> */}
            {modalState && <UploadCommonModel open={modalState} close={() => handleClose()} />}
        </>
    );
};

export default SearchCard;
