import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Testing Page
const PdfPage = Loadable(lazy(() => import('views/pdf-page')));

// Dashboard page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const GenerateDeck = Loadable(lazy(() => import('views/dashboard/Deck/GenerateDeck')));
const GenerateVideo = Loadable(lazy(() => import('views/dashboard/Video/GenerateVideo')));

// Output Page
const Output = Loadable(lazy(() => import('views/output')));
const DeckComponent = Loadable(lazy(() => import('views/output/Deck/Deck')));
const VideoComponent = Loadable(lazy(() => import('views/output/Video/Video')));
const ReadingComponent = Loadable(lazy(() => import('views/output/InteractivePdf/InteractiveReading')));
const NotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const Error500 = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/pdf',
            element: <PdfPage />
        },
        {
            path: '/',
            element: <DashboardPage />
        },
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/generate-video/:id/:type',
            element: <GenerateVideo />
        },
        {
            path: '/generate-deck/:id/:type',
            element: <GenerateDeck />
        },
        {
            path: '/output/:id',
            element: <Output />
        },
        {
            path: '/deck/:id',
            element: <DeckComponent />
        },
        {
            path: '/video/:id',
            element: <VideoComponent />
        },
        {
            path: '/interactive-reading/:id',
            element: <ReadingComponent />
        },
        { path: '*', element: <NotFound /> },
        { path: '/error-500', element: <Error500 /> }
    ]
};

export default MainRoutes;
