import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processUserQuery } from "../../utils";
import {
  addToHistory,
  resetSelectedContext,
  toggleShowLoader,
} from "../../features";
import toast from "react-hot-toast";

const ChatInput = ({ resourceId, isInRecordMode }) => {
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const playgroundState = useSelector((state) => state?.playground);
  const { domain } = playgroundState;

  const chatState = useSelector((state) => state?.chat);
  const { selectedContext: context, page, bbox } = chatState;

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      if (!text.trim()) {
        toast.error("Message is empty!");
        return;
      }

      dispatch(addToHistory({
        sender: "user",
        message: "Ask About : "+context
      }));

      const userMessage = {
        sender: "user",
        message: text,
        context,
      };
      dispatch(addToHistory(userMessage));

      
      setText("");
      dispatch(resetSelectedContext());
      dispatch(toggleShowLoader());
      const payload = {
        type: context ? "Ask a Question" : "Chat",
        text,
        domain,
        resourceId,
      };
      if (context) {
        payload.context = context;
        payload.page = page;
        payload.bbox = bbox ? bbox : [1, 2, 3, 4];
      }
      const botResponse = await processUserQuery(payload);
      const botMessage = {
        sender: "bot",
        message: botResponse,
      };
      dispatch(addToHistory(botMessage));
      dispatch(toggleShowLoader());
    }
  };

  return (
    <div className="">
      <textarea
        className={
          "border-1 w-full rounded-lg shadow-lg border border-slate-800/70 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 px-2 bg-explore py-2 " +
          (isInRecordMode ? "text-lg" : "")
        }
        rows={3}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Ask a Question..."
      />
    </div>
  );
};

export { ChatInput };
