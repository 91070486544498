import React, { useRef, useState } from "react";
import { Input } from "../Input/Input";
import BlockTextArea from "../BlockTextArea/BlockTextArea";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";
import { useDetectClick } from "../../hooks";
import { useDispatch } from "react-redux";
import { deleteVideoBlock, updateVideoBlockVoiceover } from "../../features";
import VoiceoverInput from "../VoiceoverInput/VoiceoverInput";
import { FaCheck } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";

const DeckBlockItem = ({
  block,
  handleSubTitleUpdate,
  handleDisplayTextUpdate,
  handleVoiceoverUpdate,
  shotId,
  handleDeleteBlock,
  subHeadingEdit
}) => {
  const {
    id: blockId,
    type,
    displayText,
    url,
    bBox,
    pageNumber,
    voiceoverText,
    title,
  } = block;
  const [isVoiceoverEdit, setIsVoiceoverEdit] = useState(false);
  const [isDisplaTextEdit, setIsDisplaTextEdit] = useState(false);
  const voiceiverTextAreaRef = useRef(null);
  const voiceoverInputRef = useRef(null);
  const displayTextAreaRef = useRef(null);
  const displayTextInputRef = useRef(null);
  const [isSubTitleEdit, setIsSubTitleEdit] = useState(false);

  const toggleSubTitleEdit = () => {
    setIsSubTitleEdit((prev) => !prev);
  };

  const dispatch = useDispatch();

  const updateVoiceOver = (e) => {
    handleVoiceoverUpdate({
      shotId,
      blockId,
      newVoiceoverText: e.target.value,
    });
  };

  const updateDisplayText = (e) => {
    handleDisplayTextUpdate({
      shotId,
      blockId,
      newDisplayText: e.target.value,
    });
  };

  const textAreaClasses =
    "mx-0 px-5 py-3 min-h-[35vh] rounded-none leading-[28px] ";
  const displayTextClasses =
    "mx-0 px-5 py-3 min-h-[35vh] rounded-none leading-[28px]  text-lg";
  //   useDetectClick(titleInputRef, () => {
  //     if (isTitleEdit) {
  //       setIsTitleEdit(false);
  //     }
  //   });

  useDetectClick(voiceoverInputRef, () => {
    if (isVoiceoverEdit) {
      setIsVoiceoverEdit(false);
    }
  });

  const handleVoiceoverClick = () => {
    if (!isVoiceoverEdit) {
      console.log("Start edit");
      setIsVoiceoverEdit(true);
    }
  };

  useDetectClick(displayTextInputRef, () => {
    if (isDisplaTextEdit) {
      setIsDisplaTextEdit(false);
    }
  });
  const handleDisplayTextClick = () => {
    if (!isDisplaTextEdit) {
      setIsDisplaTextEdit(true);
    }
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3 rounded-t-lg">
      {isSubTitleEdit ? (
            <Input
              value={title}
              // updateValue={() => handleTitleUpdate({shotId, })}
              onChange={(e) =>
                handleSubTitleUpdate({ shotId, blockId, newTitle: e.target.value })
              }
              classes={" ml-2"}
              // eleRef={titleInputRef}
            />
          ) : (
            <h3 className=" font-bold ml-2 capitalize">{title} - </h3>
          )}
      
      {subHeadingEdit && (isSubTitleEdit ? (
            <div className="cursor-pointer">
              <FaCheck onClick={toggleSubTitleEdit} />
            </div>
          ) : (
            <div className="cursor-pointer">
              <MdModeEdit onClick={toggleSubTitleEdit} />
            </div>
          ))}
          </div>
      <div className="flex justify-end mr-2 cursor-pointer">
      
        <MdDelete
          onClick={() => {
            handleDeleteBlock(shotId, blockId);
          }}
        />
      </div>
      <div
        className=""
        ref={displayTextInputRef}
        onClick={handleDisplayTextClick}
      >
        {type === "text" ? (
          isDisplaTextEdit ? (
            <div className="mx-0 my-2">
              <VoiceoverInput
                value={displayText}
                onChange={updateDisplayText}
                classes={textAreaClasses}
                showBoxShadow={true}
                autoFocusOnRender={true}
                minHeight="min-h-[5vh]"
                eleref={displayTextAreaRef}
              />
            </div>
          ) : (
            <div className="rounded-lg relative m-0">
              <MarkdownRenderer
                content={displayText}
                classes="shadow-lg px-0 py-0 cursor-text rounded-none m-0 p-3 text-2xl"
                placeHolder="Enter display-text here"
                minHeight="min-h-[5vh]"
              />
            </div>
          )
        ) : (
          <div className="flex justify-center items-center">
            <img src={url} alt="" />
          </div>
        )}
      </div>

      <div className="">
        {/* <blockquote>{voiceoverText}</blockquote> */}

        <div
          onClick={handleVoiceoverClick}
          ref={voiceoverInputRef}
          className="relative"
          // className={"relative " + (isEditing ? "" : "bg-[#74c5ff]")}
        >
          {/* <div
            className="absolute cursor-pointer top-2 right-3 z-10 "
            onClick={handleDeleteBlock}
          >
            <MdDelete />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export { DeckBlockItem };
