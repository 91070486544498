import React, { useState } from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { processInteractivePdf } from "../../utils";
const ProcessingOverlay = ({ toggleOverlay, processingData }) => {
  const navigate = useNavigate();
  const [loader, showLoader] = useState(false);
  const handleGoHome = async () => {
    // toggleOverlay();
    showLoader(true);
    const res = await processInteractivePdf({
      outputFileName: processingData.title,
      audiencePersona: "beginner",
      includeFaqs: false,
      includeTerminologies: false,
      includeQuiz: false,
      pdfUrl: processingData.url,
      resourceId: processingData._id,
      domain: processingData.domain,
      sections: [],
      highlights: [],
    });

    navigate("/outputs/" + processingData._id);
    // navigate("/");
  };
  return (
    <div className="overlay flex justify-center items-center ">
      <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-5 rounded relative">
        {loader ? (
          <div className="loader"></div>
        ) : (
          <>
            <div
              className="absolute top-1 right-1 cursor-pointer"
              onClick={(e) => toggleOverlay()}
            >
              <IoMdCloseCircleOutline />
            </div>
            <h3 className="text-center font-bold text-2xl mb-3 mt-2">
              Create Interactive Document
            </h3>
            <p>Shall we proceed with generating the interactive document?</p>
            <p>
              <Button onClick={handleGoHome}>Yes</Button>&nbsp;
              <Button onClick={toggleOverlay} className="denger">
                No
              </Button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export { ProcessingOverlay };
