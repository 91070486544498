// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const PROXY = `${process.env.REACT_APP_BACKEND_BASE_URL}/`;
// const PROXY = `http://64.227.191.234:8000/`;

const initialState = {
    getDeckData: { shots: [] },
    getDeckDataLoading: true,
    getDeckUrlData: {},
    getDeckUrlLoading: true,
    error: null
};

const slice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        Get_Deck_Data(state, action) {
            state.getDeckData = action.payload;
            state.getDeckDataLoading = false;
        },
        Get_Deck_Url(state, action) {
            state.getDeckUrlData = action.payload;
            state.getDeckUrlLoading = false;
        },

        // Update title of video
        Update_DeckTitle(state, action) {
            const { shotIndex, title } = action.payload;
            state.getDeckData.shots[shotIndex] = {
                ...state.getDeckData.shots[shotIndex],
                title
            };
        },

        // Update title inside a shot
        Update_SubTitle(state, action) {
            const { shotIndex, fieldIndex, title } = action.payload;
            if (state.getDeckData?.shots[shotIndex]?.blocks[fieldIndex]) {
                state.getDeckData.shots[shotIndex].blocks[fieldIndex].title = title;
            }
        },

        // Update displayText inside a shot
        Update_DisplayText(state, action) {
            const { shotIndex, fieldIndex, displayText } = action.payload;
            state.getDeckData.shots[shotIndex].blocks[fieldIndex] = {
                ...state.getDeckData.shots[shotIndex].blocks[fieldIndex],
                displayText
            };
        },

        // remove deck index
        Remove_Deck_Index(state, action) {
            const { shotIndex } = action.payload;
            state.getDeckData.shots = state.getDeckData.shots.filter((_, index) => index !== shotIndex);
        },

        //  remove Sub deck index
        Remove_Sub_title_Index: (state, action) => {
            const { shotIndex, fieldIndex } = action.payload;
            const shot = state.getDeckData.shots[shotIndex];

            if (shot && shot.blocks) {
                shot.blocks = shot.blocks.filter((_, index) => index !== fieldIndex);
            }
        },

        //  swap Sub deck index
        Swap_Deck_Index: (state, action) => {
            const { index1, index2 } = action.payload;
            const shots = state.getDeckData.shots;

            if (index1 >= 0 && index2 >= 0 && index1 < shots.length && index2 < shots.length) {
                [shots[index1], shots[index2]] = [shots[index2], shots[index1]]; // Swap
            }
        },

        hasError(state, action) {
            state.error = action.payload;
        }
    }
});

export const {
    LogoutSession,
    openPopupModel,
    Update_DeckTitle,
    Update_SubTitle,
    Update_DisplayText,
    Swap_Deck_Index,
    Remove_Sub_title_Index,
    Remove_Deck_Index
} = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const GetAllDeckDataApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_deck_template_data`, data);
        dispatch(slice.actions.Get_Deck_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GenerateDeckApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}generate_deck`, data);
        // dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};
// http://64.227.191.234:8000/get_output_details?output_id=67a1db7c435cf8e47355e31d
export const GetDeckApi =
    (outputId = '') =>
    async () => {
        try {
            const response = await axios.get(`${PROXY}get_output_details?output_id=${outputId}`);
            dispatch(slice.actions.Get_Deck_Url(response.data));
            return response.data;
        } catch (error) {
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };
