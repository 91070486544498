import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../Button/Button";
import toast from "react-hot-toast";
import { ShotPreviewCard } from "../ShotPreviewCard/ShotPreviewCard";

const videoShots = {
  id: "123",
  title: "Tirzepatide Once Weekly for the Treatment of Obesity",
  shots: [
    {
      id: 1,
      url: "https://storage.googleapis.com/tsoai-resources-data/Tirzepatide_v1.mp4",
      title: "Introduction",
    },
    {
      id: 1,
      url: "https://storage.googleapis.com/tsoai-resources-data/Tirzepatide_v1.mp4",
      title: "Abstract",
    },
  ],
};

const displayText =
  "This table presents the demographic and clinical characteristics of 2539 participants at baseline from a trial conducted between December 2019 and April 2022. Participants had a mean age of 44.9 years, with 67.5 percent being female and 70.6 percent White. The average body weight was 104.8 kilograms, BMI was 38.0, and waist circumference was 114.1 centimeters. Most participants had a BMI of 30 or higher, with an average obesity duration of 14.4 years.";

const ShotsOutputPage = () => {
  const [shotsData, setShotsData] = useState("");
  const { outputId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/get_video_shots`,
        {
          outputId,
        }
      );
      setShotsData(result?.data);
      document.title = `${result?.data?.title} | Interactive Copilot`;
    })();
    // setShotsData(videoShots);
  }, []);

  const stitchShots = async () => {
    toast.success("WIP : be added to queue");
    navigate("/");
    return "Done";
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/stitch_shots`,
      {
        outputId,
      }
    );
  };
  return (
    <div className="flex flex-col">
      <h1 className="text-4xl text-center font-semibold text-gray-800 my-8">
        {shotsData?.title}
      </h1>
      <div className="flex flex-col justify-center items-center gap-3 py-4">
        {shotsData?.shots?.map((shot) => {
          const { title, id, url } = shot;
          return (
            <ShotPreviewCard
              id={id}
              title={"Video Title"}
              videoUrl={url}
              displayContent={displayText}
              displayType={"text"}
              voiceoverText={displayText}
            />
            // <div key={shot?.id} className="my-4">
            //   <h2 className="text-2xl font-medium">Shot Title</h2>
            //   {/* <h1>{shot?.id}</h1> */}
            //   <div></div>
            //   <h2 className="text-xl text-center font-semibold my-4">
            //     {shot?.title}
            //   </h2>
            //   {/* <video src={shot?.url} height={400} width={1000} controls /> */}
            // </div>
          );
        })}
      </div>
      <div className="flex justify-end mr-[5%]  mb-4">
        <Button onClick={stitchShots}>Proceed</Button>
      </div>
    </div>
  );
};

export { ShotsOutputPage };
