import notesReducer from "./notes/notesSlice";
import resourcesReducer from "./resources/resourcesSlice";
import selectionReducer from "./selection/selectionSlice";
import pdfViewerReducer from "./pdfViewer/pdfViewerSlice";
import fabricReducer from "./fabric/fabricSlice";
import chatReducer from "./Chat/chatSlice";
import playgroundReducer from "./playground/playgroundSlice";
import editTermsReducer from "./terms/editTermsSlice";
import videoShotsReducer from "./videoShots/videoShotsSlice";
import highlightsReducer from "./highlights/highlightsSlice";

export {
  addNote,
  removeAll,
  updateCurrentNote,
  resetCurrentNote,
  toggleEditNode,
  updateNote,
  deleteNote,
  rearrangeNotes,
  resetsectionsList,
  createNewSection,
  addBlockToSection,
  updateBlockNote,
  updateTitle,
  deleteBlock,
  deleteSection,
  setEditingBlock,
  resetEditingBlock,
} from "./notes/notesSlice";

export {
  addResource,
  overWriteResourcesList,
} from "./resources/resourcesSlice";

export { setSelection, resetSelection } from "./selection/selectionSlice";

export {
  toggleShowOverlay,
  setPageNumber,
  updateDimension,
} from "./pdfViewer/pdfViewerSlice";

export {
  setSelectedTool,
  resetSelectedTool,
  updateTool,
  updateData,
  toggleFileUploadModal,
} from "./fabric/fabricSlice";

export {
  addToHistory,
  setSelectedContext,
  resetSelectedContext,
  toggleShowLoader,
  resetHistory,
} from "./Chat/chatSlice";

export { setDomain, toggleRecordMode } from "./playground/playgroundSlice";

export { setEditingTerm, resetEditingTerm } from "./terms/editTermsSlice";

export {
  setVideoShots,
  updateVideoShotTitle,
  updateVideoShotSubTitle,
  updateVideoShotSubSectionTitle,
  updateVideoBlockDisplayText,
  updateVideoBlockVoiceover,
  setVideoShotsSchema,
  deleteVideoShot,
  deleteVideoBlock,
  addVideoBlock,
  addVideoShot,
} from "./videoShots/videoShotsSlice";

export {
  setHighlightSelectedText,
  updateHighlightAnnotation,
  addHighlightAnnotation,
  deleteHighlightAnnotation,
  toggleHighlightInputModal,
  resetHighlightSelection,
} from "./highlights/highlightsSlice";

export {
  notesReducer,
  selectionReducer,
  resourcesReducer,
  pdfViewerReducer,
  fabricReducer,
  chatReducer,
  playgroundReducer,
  editTermsReducer,
  videoShotsReducer,
  highlightsReducer,
};
