import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PDFView from "../../PDFView";
import { Button } from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addToHistory,
  resetSelectedContext,
  setSelectedContext,
  toggleShowLoader,
} from "../../features";
import { getFAQs, getMenuItems, processUserQuery } from "../../utils";
import axios from "axios";
import { dummyData } from "../../test";
// import { nanoid } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";

let pdfView;
function loadPDFResource(
  pdfRendererRef,
  handlePDFEvent,
  sUrlPDF,
  sUrlJSON,
  referenceSection
) {
  pdfView = new PDFView();
  pdfView.mount(pdfRendererRef);
  pdfView.onEvent = handlePDFEvent;
  pdfView.doLoadResources(sUrlPDF, sUrlJSON);
  // pdfView.doLoadResources(
  //   "https://storage.googleapis.com/pdf-explainer/supporting_docs/computer_network.pdf",
  //   "https://storage.googleapis.com/pdf-explainer/supporting_docs/cn_terms.json"
  // );

  const { pageNum, blockId } = referenceSection;
  pdfView.setReferenceSection(pageNum, blockId);
}

const InteractivePDFRenderer = ({
  resourceUrl,
  resourceId,
  outputId,
  referenceSection,
}) => {
  const navigate = useNavigate();
  const pdfRendererRef = useRef(null);
  const [nPages, setPages] = useState(0);
  const [nPage, setPage] = useState(0);
  const [showSectionsDropdown, setShowSectionsDropdown] = useState(false);
  const [sectionsDropdownCoords, setSectionsDropdownCoords] = useState({
    x: 0,
    y: 0,
  });

  const dispatch = useDispatch();

  const chatState = useSelector((state) => state?.chat);
  const { selectedContext } = chatState;
  const playgroundState = useSelector((state) => state?.playground);
  const { domain } = playgroundState;
  const [publicationsList, setPublicationsList] = useState([]);
  const [referencesList, setReferencesList] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [zoom, setZoom] = useState(100);
  // console.log("\n\n\n playgroundState : ", { playgroundState });
  useEffect(() => {
    loadPDFResource(
      pdfRendererRef.current,
      handlePDFEvent,
      // `https://storage.googleapis.com/tsoai-resources-data/resources/646fa564ae4796bcd4b31020.pdf`,
      // "https://arxiv.org/abs/"
      // "https://arxiv.org/pdf/1706.03762v5.pdf"
      // "https://storage.googleapis.com/tsoai-resources-data/Lawn_Boy.pdf",
      // "https://storage.googleapis.com/tsoai-resources-data/tablets_1.pdf",
      resourceUrl,
      `${process.env.REACT_APP_BACKEND_BASE_URL}/terminologies?output_id=${outputId}`,
      referenceSection
    );
  }, []);

  useEffect(() => {
    (async () => {
      console.log("resourceId : ", resourceId);
      const publications = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/publications?resource_id=${resourceId}`
      );
      const references = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/references?resource_id=${resourceId}`
      );
      const highlights = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/highlights?resource_id=${resourceId}`
      );
      // setHighlights({
      //   id: "-EyXTNgigDmrP1EhFVcC-",
      //   text: "Obesity is a chronic disease that results in substantial global morbidity and mortality. The efficacy and safety of tirzepatide, a novel glucose-dependent insulinotropic polypeptide and glucagon-like peptide-1 receptor agonist, in people with obesity are not known.",
      //   annotation: "This is the abstract section",
      // });
      setPublicationsList([...publications?.data]);
      setReferencesList([
        ...references?.data,
        {
          _id: "6697d5da41e87fd54f2df13b",
          reference:
            "17. Garvey WT, Mechanick JI, Brett EM, et al. American Association of Clinical Endocrinologists and American College of Endocrinology comprehensive clinical practice guidelines for medical care of pa-tients with obesity. Endocr Pract 2016; 22: Suppl 3: 1-203.",
          regex:
            "\\w{5}\\s+\\w{1},\\s+\\w{6}\\s+\\w{1},\\s+\\w{8}\\s+\\w{1},\\s+\\w{2}\\s+\\w{2}.\\s+\\w{3}\\s+\\w{3}\\s+\\w{5}\\s+\\w{5}\\s+\\w{6}\\s+\\w{5}\\s+\\w{7}.\\s+\\w{8}\\s+\\w{6}\\s+\\w{9},\\s+\\w{8}\\s+\\d{1},\\s+\\d{4}\\s+(\\w{5}://\\w{3}\\s+.\\s+\\w{8}\\s+.\\s+\\w{3}/\\s+\\w{10}/\\s+\\w{10}\\s+\\w{7}\\s+\\w{3}\\s+\\w{8}/\\s+\\w{3}\\s+\\w{8}/\\s+\\w{3}\\s+\\w{3}\\s+\\w{5}\\s+\\w{5}\\s+\\w{6}\\s+\\w{5}\\s+\\w{7}).\\s+",
          resource_id: "abcde",
          url: "https://pubmed.ncbi.nlm.nih.gov/27472012/",
        },
        {
          _id: "6697d5da41e87fd54f2df13b",
          reference:
            "17. Garvey WT, Mechanick JI, Brett EM, et al. American Association of Clinical Endocrinologists and American College of Endocrinology comprehensive clinical practice guidelines for medical care of pa-tients with obesity. Endocr Pract 2016; 22: Suppl 3: 1-203.",
          regex:
            "(\\w{5}\\s+\\w{2},\\s+\\w{7}\\s+\\w{2},\\s+\\w{5}\\s+\\w{2}\\.\\s+\\w{3}\\s+\\w{3}\\s+\\w{3}\\s+\\w{7}\\s+\\w{3}\\s+\\w{11}\\s+\\w{2}\\-\\s+\\w{6}\\s+\\w{2}\\s+\\w{3}\\-\\d{1}\\?\\s+\\w{6}\\s+\\w{10}\\s+\\w{5}\\s+\\d{4};\\s+\\d{2}:\\s+\\d{3}\\-\\d{2}\\.)",
          resource_id: "abcde",
          url: "https://www.google.com",
        },
      ]);
      setHighlights([...highlights?.data]);
      // setHighlights([
      //   {
      //     id: "-EyXTNgigDmrP1EhFVcC-",
      //     text: "Obesity is a chronic disease that results in substantial global morbidity and mortality. The efficacy and safety of tirzepatide, a novel glucose-dependent insulinotropic polypeptide and glucagon-like peptide-1 receptor agonist, in people with obesity are not known.",
      //     annotation:
      //       "Obesity is a chronic condition that significantly impacts global health, contributing to considerable morbidity and mortality. Tirzepatide, a novel medication that functions as both a glucose-dependent insulinotropic polypeptide (GIP) receptor agonist and a glucagon-like peptide-1 (GLP-1) receptor agonist, is being evaluated for its potential use in treating obesity. However, as of now, the efficacy and safety of tirzepatide specifically for obesity management have not been established.",
      //     regex:
      //       "Obesity\\s+\\w{2}\\s+\\w{1}\\s+\\w{7}\\s+\\w{7}\\s+\\w{4}\\s+\\w{7}\\s+\\w{2}\\s+\\w{11}\\s+\\w{6}\\s+\\w{9}\\s+\\w{3}\\s+\\w{3}\\-\\s+\\w{6}\\.\\s+\\w{3}\\s+\\w{8}\\s+\\w{3}\\s+\\w{6}\\s+\\w{2}\\s+\\w{11}\\,\\s+\\w{1}\\s+\\w{5}\\s+\\w{7}\\-\\w{9}\\s+\\w{8}\\-\\s+\\w{6}\\s+\\w{11}\\s+\\w{3}\\s+\\w{8}\\-\\w{4}\\s+\\w{7}\\-\\d{1}\\s+\\w{8}\\s+\\w{7}\\,\\s+\\w{2}\\s+\\w{6}\\s+\\w{4}\\s+\\w{7}\\s+\\w{3}\\s+\\w{3}\\s+known.",
      //   },
      //   {
      //     id: "-wwwEyXTNgigDmrP1EhFVcC-",
      //     text: "In this phase 3 double-blind, randomized, controlled trial, we assigned 2539 adults with a body-mass index (BMI; the weight in kilograms divided by the square of the height in meters) of 30 or more, or 27 or more and at least one weight-related complication, excluding diabetes, in a 1:1:1:1 ratio to receive once-weekly, subcutaneous tirzepatide (5 mg, 10 mg, or 15 mg) or placebo for 72 weeks, including a 20-week dose-escalation period.",
      //     annotation:
      //       "In a Phase 3 trial, 2,539 adults with obesity or overweight and related complications (excluding diabetes) were randomized to receive weekly subcutaneous injections of tirzepatide (5 mg, 10 mg, or 15 mg) or placebo for 72 weeks, including a 20-week dose escalation phase.",
      //     regex:
      //       "In this phase 3 double-blind, randomized",
      //   },
      //   {
      //     id: "-wwwEyXTNgigDmrP1EhFVcCww-",
      //     text: "In this phase 3 double-blind, randomized, controlled trial, we assigned 2539 adults with a body-mass index (BMI; the weight in kilograms divided by the square of the height in meters) of 30 or more, or 27 or more and at least one weight-related complication, excluding diabetes, in a 1:1:1:1 ratio to receive once-weekly, subcutaneous tirzepatide (5 mg, 10 mg, or 15 mg) or placebo for 72 weeks, including a 20-week dose-escalation period.",
      //     annotation:
      //       "In a Phase 3 trial, 2,539 adults with obesity or overweight and related complications (excluding diabetes) were randomized to receive weekly subcutaneous injections of tirzepatide (5 mg, 10 mg, or 15 mg) or placebo for 72 weeks, including a 20-week dose escalation phase.",
      //     regex:
      //       "Coprimary end points were the percentage  change in weight from baseline and a weight reduction of 5% or more. The  treatment-regimen estimand assessed effects regardless of treatment discontinua-  tion in the intention-to-treat population.    RESULTS    At baseline, the mean body weight was",
      //   },
      // ]);
    })();
  }, []);

  useEffect(() => {
    pdfView.setPublicationsList(publicationsList);
  }, [publicationsList]);

  useEffect(() => {
    pdfView.setReferencesList(referencesList);
  }, [referencesList]);

  useEffect(() => {
    pdfView.setHighlightsList(highlights);
  }, [highlights]);

  // const showOverlay = useSelector((state) => state.pdfViewer.showOverlay);

  // const sectionsList = useSelector((state) => state.notes.sectionsList);

  // const getSelectionMenuItems = (newSectionsList) => {
  //   const menuItems = ["Create new section"];

  //   if (sectionsList?.length > 0) {
  //     menuItems.push("Add to section...");
  //   }
  //   return menuItems;
  // };

  // const pdfRendererRef = useRef(null);

  const handlePDFEvent = async (msg, data) => {
    console.log("MSG : ", { msg, data });
    switch (msg) {
      case "PAGE_CHANGED":
        setPage(data.nPage);
        setPages(data.nPages);
        break;

      case "TERMINOLOGY_MORE_INFO_CLICKED":
        const userMessage = {
          sender: "user",
          message: `Define :  ${data.term}`,
        };
        dispatch(addToHistory(userMessage));
        dispatch(resetSelectedContext());
        dispatch(toggleShowLoader());
        console.log({ data });
        const botResponse = await getFAQs(data?._id);
        const botMessage = {
          sender: "bot",
          type: "faq",
          term: data.term,
          faqs: botResponse,
          page: data.lt_page,
        };
        dispatch(addToHistory(botMessage));
        dispatch(toggleShowLoader());

        // postQuery(
        //   `/define ${data.term}`,
        //   "system",
        //   "command",
        //   data.page,
        //   data.bbox,
        //   data.term,
        //   { _id: data._id }
        // );
        break;

      case "TEXT_SELECTION_CONTEXT_MENU_ITEM_CLICKED":
        //alert('Menu click\n' + JSON.stringify(data, null, 2));
        if (data.item === "Ask a Question") {
          let payload = {
            text: data.text,
            bbox: data.bbox,
          };
          dispatch(
            setSelectedContext({
              text: data.text,
              page: data.lt_page,
              bbox: data.bbox,
            })
          );
        } else if (
          data.item === "Summarize" ||
          data.item === "Explain" ||
          data.item === "Define"
        ) {
          const userMessage = {
            sender: "user",
            message: `${data.item} :  ${data.text}`,
          };
          dispatch(addToHistory(userMessage));
          dispatch(resetSelectedContext());
          dispatch(toggleShowLoader());
          const botResponse = await processUserQuery({
            type: data.item,
            text: data.text,
            page: data.lt_page,
            bbox: data.bbox,
            domain,
            resourceId,
          });
          const botMessage = {
            sender: "bot",
            message: botResponse,
          };
          dispatch(addToHistory(botMessage));
          dispatch(toggleShowLoader());
        }
        // else if (data.item === "Explain") {
        // } else {
        //   const command = data.item.toLowerCase().replace(/\\s+/g, "-");
        //   // postQuery(
        //   //   `/${command} ${data.text}`,
        //   //   "system",
        //   //   "command",
        //   //   data.lt_page,
        //   //   data.bbox,
        //   //   data.text,
        //   //   {}
        //   // );
        // }
        break;

      case "TEXT_SELECTION_CONTEXT_MENU_SHOWN":
        // Set the items for the text selection context menu dynamically
        // This can be called asynchronously from a network call success, but the menu will be invisible until then
        // const menuItems = ["Explain", "Ask a Question"];
        // const spaceCount = (data.text.match(/ /g) || []).length;
        // // can use Define for upto 2 words only
        // if (spaceCount < 2) {
        //   menuItems.unshift("Define");
        // }
        // // can summarize only if the selection is atleast 26 words long
        // if (spaceCount > 25) {
        //   menuItems.push("Summarize");
        // }
        // pdfView.setMenuItems(menuItems);
        // break;

        const menuItems = [];
        const res = await getMenuItems(data.text);
        if (res?.is_term) {
          menuItems?.push("Define");
          // menuItems?.push("Learn more");
        }
        if (res?.is_explainable) {
          menuItems?.push("Explain");
        }
        if (res?.is_summarizable) {
          menuItems?.push("Summarize");
        }
        if (res?.is_explainable || res?.is_summarizable) {
          menuItems?.push("Ask a Question");
        }
        pdfView.setMenuItems(menuItems);
        break;

      case "AREA_SELECTION_CONTEXT_MENU_ITEM_CLICKED":
        break;
      default:
        break;
    }
  };
  return (
    <div
      className="mr-1 rounded-lg border-1 border-dashed w-full border-slate-800/70"
      id="pdf-viewer-pane"
    >
      <div id="pdf-toolbar" className="m-0">
        <div>
          <Button
            onClick={() => {
              navigate("/outputs/" + resourceId);
            }}
            className="border-slate-600 px-2 mx-1 rounded-full border-1"
          >
            Back
          </Button>
        </div>
        <div id="pdf-navigation" className="flex flex-row justify-center">
          <Button
            onClick={() => {
              if (zoom > 30) {
                pdfView.zoom(-5);
                setZoom(zoom - 5);
              }
            }}
            className="border-slate-600 px-2 mx-1 rounded-full border-1"
          >
            -
          </Button>
          <div
            id="pdf-page-indicator"
            className="border-slate-600 px-2 mx-1 rounded border"
          >
            {zoom}%
          </div>
          <Button
            onClick={() => {
              if (zoom < 200) {
                pdfView.zoom(5);
                setZoom(zoom + 5);
              }
            }}
            className="border-slate-600 px-2 mx-1 rounded-full border-1"
          >
            +
          </Button>
          &nbsp; &nbsp;
          <div id="pdf-navigation" className="flex flex-row justify-center">
            <Button
              onClick={() => {
                pdfView.gotoPageRel(-1);
              }}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              Prev
            </Button>
            <div
              id="pdf-page-indicator"
              className="border-slate-600 px-2 mx-1 rounded border"
            >
              <div>{nPage}</div> <div>/</div> <div>{nPages}</div>
            </div>
            <Button
              onClick={() => pdfView.gotoPageRel(1)}
              className="border-slate-600 px-2 mx-1 rounded-full border-1"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <div
        id="pdf-viewer-placeholder"
        style={{ border: "1px solid #dddddd" }}
        ref={pdfRendererRef}
      ></div>
    </div>
  );
};

export { InteractivePDFRenderer };
