import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { statuses } from "../../consts";
import { processInteractivePdf } from "../../utils";

const PDFListRow = ({ pdfItem, toggleShowProcessingOverlayNext, showVelocityTypePopup, showDeckVelocityTypePopup }) => {
  const [showOuputList, setShowOutputList] = useState(false);
  const toggleShowOutputList = () => {
    setShowOutputList((prev) => !prev);
  };

  const createInteractiveDocument = async  (data)=>{
    toggleShowProcessingOverlayNext(data);
    // const res = await processInteractivePdf({
    //   outputFileName: data.title,
    //   audiencePersona: 'beginner',
    //   includeFaqs: false,
    //   includeTerminologies: false,
    //   includeQuiz: false,
    //   pdfUrl: data.url,
    //   resourceId: data._id,
    //   domain: data.domain,
    //   sections: [],
    //   highlights: []
    // });
  }

 
  return (
    <tr key={pdfItem._id} className="hover:bg-gray-100">
      
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {pdfItem.title}
      </td>
      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {pdfItem.domain}
      </td> */}
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {statuses[pdfItem.status]}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {pdfItem?.status === "COMPLETED" ? (
          <Link
            // to={`/annotate/${pdfItem._id}`}
            onClick={e=>createInteractiveDocument(pdfItem)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Create Interactive Document
          </Link>
        ) : (
          <p className="cursor-not-allowed	text-slate-500">
            Create Interactive Document
          </p>
        )}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {pdfItem?.status === "COMPLETED" ? (
          <Link
            // to={`/annotate-video/${pdfItem._id}`}
            onClick={e=>showVelocityTypePopup(pdfItem._id)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Generate Video
          </Link>
          
        ) : (
          <p className="cursor-not-allowed	text-slate-500">Generate Video</p>
        )}
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {pdfItem?.status === "COMPLETED" ? (
          <Link
            // to={`/annotate-deck/${pdfItem._id}`}
            onClick={e=>showDeckVelocityTypePopup(pdfItem._id)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Generate Deck
          </Link>
        ) : (
          <p className="cursor-not-allowed	text-slate-500">Generate Deck</p>
        )}
      </td>

      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <Link
          to={`/annotate-pdf/${pdfItem._id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Generate PPT
        </Link>
      </td> */}
      <td className={"px-6 py-4 whitespace-nowrap text-sm text-gray-900 "}>
        {pdfItem.output_count ? (
          <Link
            to={`/outputs/${pdfItem._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            {pdfItem.output_count}
          </Link>
        ) : (
          pdfItem.output_count
        )}
      </td>
    </tr>
  );
};

export { PDFListRow };
