// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const PROXY = `${process.env.REACT_APP_BACKEND_BASE_URL}/`;
// const PROXY = `http://64.227.191.234:8000/`;

const initialState = {
    adminToken: localStorage.getItem('adminToken'),
    isAuthenticated: false,
    modalState: false,
    userDataLoading: true,
    getUserPdf: {
        data: [],
        page: 0,
        page_size: 0,
        total_count: 0,
        total_pages: 0
    },
    getoutputDatarPdf: {
        data: [],
        page: 0,
        page_size: 0,
        total_count: 0,
        total_pages: 0
    },
    outputDataLoading: true,
    getVideoData: { shots: [] },
    getVideDataLoading: true,
    messages: [],
    selectedContext: {},
    // getUserPdf: [],
    error: null,
    getOutputDataDetail: {},
    getPublicData: [],
    getPublicLoading: true,
    getTerminologiesData: [],
    getTerminologiesLoading: true
};

const slice = createSlice({
    name: 'Deck',
    initialState,
    reducers: {
        // Admin Login successfull
        Login_Success(state, action) {
            state.adminToken = action.payload?.token;
            state.isAuthenticated = true;
            state.userDataLoading = false;
        },

        // Admin Login Failed
        Login_Failed(state) {
            state.adminToken = null;
            state.isAuthenticated = false;
            state.userDataLoading = false;
            state.getUserPdf = [];
        },

        // Admin details
        Get_User_PDF(state, action) {
            state.getUserPdf.data = action.payload.data;
            state.getUserPdf.page = action.payload.page;
            state.getUserPdf.page_size = action.payload.page_size;
            state.getUserPdf.total_count = action.payload.total_count;
            state.getUserPdf.total_pages = action.payload.total_pages;
            state.userDataLoading = false;
        },

        // details failed
        Get_User_PDF_Failed(state) {
            state.userDataLoading = true;
            state.getUserPdf.data = [];
            state.getUserPdf.page = 0;
            state.getUserPdf.page_size = 0;
            state.getUserPdf.total_count = 0;
            state.getUserPdf.total_pages = 0;
        },

        Get_Output_Data(state, action) {
            state.getoutputDatarPdf.data = action.payload.data;
            state.getoutputDatarPdf.page = action.payload.page;
            state.getoutputDatarPdf.page_size = action.payload.page_size;
            state.getoutputDatarPdf.total_count = action.payload.total_count;
            state.getoutputDatarPdf.total_pages = action.payload.total_pages;
            state.outputDataLoading = false;
        },

        ClearOutputData(state, action) {
            state.getoutputDatarPdf.data = [];
            state.getoutputDatarPdf.page = 0;
            state.getoutputDatarPdf.page_size = 0;
            state.getoutputDatarPdf.total_count = 0;
            state.getoutputDatarPdf.total_pages = 0;
            state.outputDataLoading = true;
        },

        Get_Video_Data(state, action) {
            state.getVideoData = action.payload;
            state.getVideDataLoading = false;
        },

        Get_Output_Failed(state) {
            state.outputDataLoading = true;
            state.getoutputDatarPdf.data = [];
            state.getoutputDatarPdf.page = 0;
            state.getoutputDatarPdf.page_size = 0;
            state.getoutputDatarPdf.total_count = 0;
            state.getoutputDatarPdf.total_pages = 0;
        },

        // logout
        LogoutSession(state) {
            state.adminToken = null;
            state.isAuthenticated = false;
            state.userDataLoading = false;
            state.getUserPdf = [];
        },

        openPopupModel(state, action) {
            state.modalState = action.payload;
        },

        // All  Erros
        hasError(state, action) {
            state.error = action.payload;
        },

        // Update title of video
        Update_Video_Title(state, action) {
            const { shotIndex, title } = action.payload;
            state.getVideoData.shots[shotIndex] = {
                ...state.getVideoData.shots[shotIndex],
                title
            };
        },

        // Update subsection inside a shot
        Update_Subsection(state, action) {
            const { shotIndex, fieldIndex, subsection } = action.payload;
            if (state.getVideoData?.shots[shotIndex]?.blocks[fieldIndex]) {
                state.getVideoData.shots[shotIndex].blocks[fieldIndex].subsection = subsection;
            }
        },

        // Update voiceoverText inside a shot
        Update_VoiceoverText(state, action) {
            const { shotIndex, fieldIndex, voiceoverText } = action.payload;
            state.getVideoData.shots[shotIndex].blocks[fieldIndex] = {
                ...state.getVideoData.shots[shotIndex].blocks[fieldIndex],
                voiceoverText
            };
        },

        Remove_Shot(state, action) {
            const { shotIndex } = action.payload;
            state.getVideoData.shots = state.getVideoData.shots.filter((_, index) => index !== shotIndex);
        },

        Remove_Sub_title_Shot: (state, action) => {
            const { shotIndex, fieldIndex } = action.payload;
            const shot = state.getVideoData.shots[shotIndex];

            if (shot && shot.blocks) {
                shot.blocks = shot.blocks.filter((_, index) => index !== fieldIndex);
            }
        },

        Swap_Shots: (state, action) => {
            const { index1, index2 } = action.payload;
            const shots = state.getVideoData.shots;

            if (index1 >= 0 && index2 >= 0 && index1 < shots.length && index2 < shots.length) {
                [shots[index1], shots[index2]] = [shots[index2], shots[index1]]; // Swap
            }
        },

        AddMessage: (state, action) => {
            state.messages.push(action.payload);
        },

        ClearMessage: (state, action) => {
            state.messages = [];
        },

        setSelectedContext: (state, action) => {
            state.selectedContext = action.payload;
        },

        Get_Output_Details_Data: (state, action) => {
            state.getOutputDataDetail = action.payload;
        },

        Get_Public_Data(state, action) {
            state.getPublicData = action.payload;
            state.getPublicLoading = false;
        },
        Get_Terminologies_Data(state, action) {
            state.getTerminologiesData = action.payload;
            state.getTerminologiesLoading = false;
        }
    }
});

export const {
    LogoutSession,
    openPopupModel,
    Swap_Shots,
    Remove_Sub_title_Shot,
    Remove_Shot,
    Update_Video_Title,
    Update_Subsection,
    Update_VoiceoverText,
    AddMessage,
    ClearMessage,
    setSelectedContext,
    ClearOutputData
} = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const GetUserAllPdfApi =
    (page = '', pageSize = '') =>
    async () => {
        try {
            const response = await axios.get(`${PROXY}get_all_pdfs?page=${page}&page_size=${pageSize}`);
            dispatch(slice.actions.Get_User_PDF(response.data));
            return response.data.data;
        } catch (error) {
            dispatch(slice.actions.Get_User_PDF_Failed(error));
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };

export const GetAllVideoDataApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_video_template_data`, data);
        dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GetAllDeckDataApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_deck_template_data`, data);
        dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GetOuputDataApi =
    (page = '', pageSize = '', id = '') =>
    async () => {
        try {
            const response = await axios.get(`${PROXY}get_outputs?resource_id=${id}&page=${page}&page_size=${pageSize}`);
            dispatch(slice.actions.Get_Output_Data(response.data));
            return response.data.data;
        } catch (error) {
            // console.log('🚀 ~ error:', error);
            dispatch(slice.actions.Get_Output_Failed(error));
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };

export const UpdateOutputApi =
    (id = '', title = '') =>
    async () => {
        try {
            const response = await axios.put(`${PROXY}update_output_title?document_id=${id}&new_title=${title}`);
            // /update_output_title
            return response.data;
        } catch (error) {
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };

export const GenrateDocsApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}interactive_document`, data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};
export const UploadResourceApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}upload_resources`, data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GenrateDeckApi =
    (id = '', title = '') =>
    async () => {
        try {
            const response = await axios.post(`${PROXY}update_output_title?document_id=${id}&new_title=${title}`);
            // /update_output_title
            return response.data;
        } catch (error) {
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };
export const GenrateVideoApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}update_output_title`, data);
        // /update_output_title
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GenerateVideoApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}generate_video`, data);
        // dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GetResource = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_resource`, data);
        // dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const getOutputDetails = (outputId) => async () => {
    try {
        const response = await axios.get(`${PROXY}get_output_details?output_id=${outputId}`);
        dispatch(slice.actions.Get_Output_Details_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GetItemMenuApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_menu_items`, data);
        // dispatch(slice.actions.Get_Video_Data(response.data));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const ChatQueryProcessApi = (data) => async () => {
    try {
        const response = await axios.post(`${PROXY}process_user_query`, data);
        // dispatch(slice.actions.Get_Video_Data(response.data));
        dispatch(AddMessage({ id: Date.now(), sender: 'bot', text: response.data }));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const getFAQs = (termId) => async () => {
    try {
        const response = await axios.get(`${PROXY}get_faqs?term_id=${termId}`);
        dispatch(AddMessage({ id: Date.now(), sender: 'bot', type: 'faq', text: response.data }));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const VideoPlayApi = (id) => async () => {
    try {
        const response = await axios.post(`${PROXY}get_video`, { output_id: id });
        // dispatch(slice.actions.Get_Video_Data(response.data));
        // dispatch(AddMessage({ id: Date.now(), sender: 'bot', text: response.data }));
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
        window.location.href = '/error-500';
        return error;
    }
};

export const GetPublicationsDataApi =
    (resourceId = '') =>
    async () => {
        try {
            const response = await axios.get(`${PROXY}publications?resource_id=${resourceId}`);
            dispatch(slice.actions.Get_Public_Data(response.data));
            return response.data.data;
        } catch (error) {
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };

export const GetTerminologiesDataApi =
    (outputId = '') =>
    async () => {
        try {
            const response = await axios.get(`${PROXY}terminologies?output_id=${outputId}`);
            dispatch(slice.actions.Get_Terminologies_Data(response.data));
            return response.data.data;
        } catch (error) {
            console.error('API Error:', error);
            dispatch(slice.actions.hasError(error?.response?.data || 'An error occurred'));
            window.location.href = '/error-500';
            return error;
        }
    };
