import React, { useEffect, useState } from "react";
import { dateToString, getOutputs } from "../../utils";
import { Link } from "react-router-dom";
import { MdContentCopy, MdEdit } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";
import { Button } from "../Button/Button";
import { statuses } from "../../consts";
import { Input } from "../Input/Input";
import axios from "axios";
import { Pagination } from "../index";

const OutputList = ({ resourceId }) => {
  const [outputs, setoutputs] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [editTitlePopUP, setEditTitlePopUP] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    (async () => {
      let pageNum = localStorage.getItem("outputPage")?JSON.parse(localStorage.getItem("outputPage"))[resourceId]:1;
      const newOutputs = await getOutputs(resourceId, pageNum, pageSize);
      setoutputs(newOutputs.data);
      setPage(newOutputs?.page);
      setTotalCount(newOutputs?.total_count);
    })();
  }, []);

  const changePage = async (curpage) => {
    const newOutputs = await getOutputs(resourceId, curpage, pageSize);
    setoutputs(newOutputs.data);
    setPage(newOutputs?.page);
    localStorage.setItem("outputPage",`{"${resourceId}":${newOutputs?.page}}`)
    setTotalCount(newOutputs?.total_count);
  };

  // polling without loader
  useEffect(() => {
    const myInterval = setInterval(fetchQuote, 10000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const fetchQuote = async () => {
    let pageNum = localStorage.getItem("outputPage")?JSON.parse(localStorage.getItem("outputPage"))[resourceId]:1;
    const newOutputs = await getOutputs(resourceId, pageNum, pageSize);
    setoutputs(newOutputs.data);
    setPage(newOutputs?.page);
    setTotalCount(newOutputs?.total_count);
  };

  // const copyLink = (eleId) => {
  //   try {
  //     navigator.clipboard.writeText(
  //       `${process.env.REACT_APP_FRONTEND_BASE_URL}/interact/${eleId}`
  //     );
  //     toast.success("Link copied");
  //   } catch (e) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const editTitleFun = (id, title) => {
    setId(id);
    setTitle(title);
    setEditTitlePopUP(true);
  };

  const updateTitle = async () => {
    if (title?.trim().length < 1) {
      toast.error("Please enter a valid title");
      return;
    }
    const promise = axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/update_output_title?document_id=${id}&new_title=${title}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setEditTitlePopUP(false);
    toast.promise(promise, {
      loading: "in-progress",
      success: "Title updated successfully",
      error: "Error",
    });

    const response = await promise;
    const newOutputs = await getOutputs(resourceId, page, pageSize);
    setoutputs(newOutputs.data);
  };

  const getCTAEle = (id, type, status) => {
    let path = "";

    switch (type) {
      case "interactive_reading":
        path = "/interact";
        break;
      case "video":
      case "short video":
      case "long video":
        path = "/videos";
        break;
      case "deck":
      case "short deck":
      case "long deck":
        path = "/decks";
        break;
      default:
        break;
    }

    if (status === "COMPLETED") {
      return (
        <>
          <Link
            to={`${path}/${id}`}
            target="_blank"
            className="text-indigo-600 hover:text-indigo-900"
          >
            checkout
          </Link>
          {/* <MdContentCopy
            className="cursor-pointer"
            onClick={() => copyLink(id)}
          /> */}
        </>
      );
    } else if (status === "waiting for approval") {
      return (
        <>
          <Link
            to={`/shots/${id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Processing...
          </Link>
        </>
      );
    } else if (status === "ERROR") {
      return (
        <div
          to={`/shots/${id}`}
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => toast.success("WIP")}
        >
          retry
        </div>
      );
    } else {
      return <p className="cursor-not-allowed	text-slate-500">Checkout</p>;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Date and Time
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Checkout
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {outputs.map((ele) => {
            return (
              <tr key={ele._id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex">
                  {ele.title} &nbsp;
                  <MdEdit
                    className="cursor-pointer"
                    onClick={() => editTitleFun(ele._id, ele.title)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {ele.type}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {/* {ele.domain} */}
                  {dateToString(ele?.created_at)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {/* {ele.domain} */}
                  {statuses[ele?.status]}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex gap-3 items-center">
                  {getCTAEle(ele?._id, ele?.type, ele?.status)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {Math.ceil(totalCount / pageSize) > 1 && (
        <Pagination
          curPage={page}
          totalPages={Math.ceil(totalCount / pageSize)}
          changePage={changePage}
        />
      )}

      {editTitlePopUP && (
        <div className="overlay flex justify-center items-center">
          <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-5 rounded relative">
            <div
              className="absolute top-1 right-1 cursor-pointer"
              onClick={(e) => setEditTitlePopUP(false)}
            >
              <IoMdCloseCircleOutline />
            </div>
            <h3 className="text-center font-bold text-2xl mb-3 mt-2">
              Update Title
            </h3>
            <p>
              <Input
                placeHolder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Input>
            </p>
            <Button onClick={(e) => updateTitle()}>Update</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { OutputList };
