import React, { useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
const Pagination = ({ curPage, totalPages, changePage }) => {
  const PaginationHtml = () => {
    var surroundingPages = [];
    for (var i = curPage; i > 0; i--) {
      // Break if we're at 2 entries added.
      if (curPage - i > 2) {
        break;
      }
      surroundingPages[i] = true;
    }

    for (i = curPage; i <= totalPages; i++) {
      // Break if we're at 2 entries added.
      if (i - curPage > 2) {
        break;
      }

      surroundingPages[i] = true;
    }

    // Create variable to store the last string
    // type added, so we can add an ellipsis
    // when needed.
    var lastStringType = "";
    let pageHtml = [];
    if (curPage != 1) {
      pageHtml.push(
        <Link
          item
          onClick={(e) => {
            changePage(curPage - 1);
            localStorage.setItem("newPage", curPage - 1);
          }}
          active={i == curPage}
          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon aria-hidden="true" className="size-5" />
        </Link>
      );
    }
    // Start the loop.
    for (let i = 1; i <= totalPages; i++) {
      // If this is the first iteration.
      if (i == 1) {
        pageHtml.push(
          <Link
            item
            onClick={(e) => {
              changePage(1);
              localStorage.setItem("newPage", 1);
            }}
            className={i == curPage ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
          >
            {1}
          </Link>
        );
        lastStringType = "number";
        continue;
      }

      // Handle first three pages.
      if (i <= 3) {
        pageHtml.push(
          <Link
            item
            onClick={(e) => {
              changePage(i);
              localStorage.setItem("newPage", i);
            }}
            className={i == curPage ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
          >
            {i}
          </Link>
        );
        lastStringType = "number";
        continue;
      }

      // Handle surrounding pages.
      if (surroundingPages[i]) {
        pageHtml.push(
          <Link
            item
            onClick={(e) => {
              changePage(i);
              localStorage.setItem("newPage", i);
            }}
            className={i == curPage ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
          >
            {i}
          </Link>
        );
        lastStringType = "number";
        continue;
      }

      // If we're within 3 iterations of the last page.
      if (i + 2 >= totalPages) {
        pageHtml.push(
          <Link
            item
            onClick={(e) => {
              changePage(i);
              localStorage.setItem("newPage", i);
            }}
            className={i == curPage ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
          >
            {i}
          </Link>
        );
        lastStringType = "number";
        continue;
      }

      // If we got here, we're 'ghosting' in between
      // the pages or skip amounts, so let's se if we
      // should output an ellipsis.
      if (lastStringType == "number") {
        pageHtml.push(<Link className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</Link>);
        lastStringType = "ellipsis";
        continue;
      }
    }
    if (totalPages > 1 && curPage != totalPages) {
      pageHtml.push(
        <Link
          item
          onClick={(e) => {
            changePage(curPage + 1);
            localStorage.setItem("newPage", curPage + 1);
          }}
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon aria-hidden="true" className="size-5" />
        </Link>
      );
    }

    return pageHtml;
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {/* <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{curPage*10}</span> to{" "}
            <span className="font-medium">{(curPage*10)+10}</span> of{" "}
            <span className="font-medium">97</span> results
          </p> */}
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <PaginationHtml/>
          </nav>
        </div>
      </div>
    </div>
  );
};
export { Pagination };
