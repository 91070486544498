import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// Predefined users (Only these users can log in)
const predefinedUsers = [
    {
        serviceToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZTg2ODA5MjgzZTI4Yjk2ZDJkMzg1MzciLCJpYXQiOjE3NDA2NDg0MjQsImV4cCI6MTc0MDczNDgyNH0.qQm0FlkoeYhqDTvRvrYRA94KEj3UdcIBW9Bk-DET69I',
        user: {
            id: '1',
            email: 'demo1@deeptuned.ai',
            name: 'Demo 1',
            role: 'Admin'
        }
    },
    {
        serviceToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZTg2ODA5MjgzZTI4Yjk2ZDJkMzg1MzciLCJpYXQiOjE3NDA2NDg0MjQsImV4cCI6MTc0MDczNDgyNH0.qQm0FlkoeYhqDTvRvrYRA94KEj3UdcIBW9Bk-DET69I',
        user: {
            id: '2',
            email: 'demo2@deeptuned.ai',
            name: 'Demo 2',
            role: 'Admin'
        }
    },
    {
        serviceToken:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZTg2ODA5MjgzZTI4Yjk2ZDJkMzg1MzciLCJpYXQiOjE3NDA2NDg0MjQsImV4cCI6MTc0MDczNDgyNH0.qQm0FlkoeYhqDTvRvrYRA94KEj3UdcIBW9Bk-DET69I',
        user: {
            id: '3',
            email: 'demo4@deeptuned.ai',
            name: 'Demo 3',
            role: 'Admin'
        }
    }
];

// Initial state
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// Verify JWT token
// const verifyToken = (serviceToken) => {
//     if (!serviceToken) return false;
//     const decoded = jwtDecode(serviceToken);
//     return decoded.exp > Date.now() / 1000;
// };
// Verify JWT token
const verifyToken = (serviceToken) => {
    if (!serviceToken) return false;

    try {
        const decoded = jwtDecode(serviceToken);
        console.log('Decoded Token:', decoded); // Debugging

        const isValid = decoded.exp * 1000 > Date.now(); // 🔥 Ensure timestamp is in milliseconds
        console.log('Is Token Valid?', isValid);

        return isValid;
    } catch (error) {
        console.error('JWT Decode Error:', error);
        return false;
    }
};

// Set session
const setSession = (serviceToken, userId) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('userId', userId); // Store user_id

        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        axios.defaults.headers.common.user_id = userId; // Set user_id in axios headers
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('userId');

        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.user_id;
    }
};

// JWT Context
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = localStorage.getItem('serviceToken');
                const userId = localStorage.getItem('userId');

                if (!serviceToken || !userId) {
                    dispatch({ type: LOGOUT });
                    return;
                }

                const matchedUser = predefinedUsers.find((user) => user.user.email === userId);

                if (matchedUser) {
                    setSession(serviceToken, matchedUser.user.email);
                    // 🔥 Ensure Redux state is properly updated
                    dispatch({
                        type: LOGIN,
                        payload: { isLoggedIn: true, user: matchedUser.user }
                    });

                    console.log('User Restored Successfully');
                } else {
                    console.log('Invalid Token. Logging out...');
                    dispatch({ type: LOGOUT });
                }
            } catch (err) {
                console.error('Error restoring session:', err);
                dispatch({ type: LOGOUT });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const matchedUser = predefinedUsers.find((user) => user.user.email === email);
        localStorage.setItem('isLoggedIn', true);
        if (!matchedUser) {
            throw new Error('Invalid credentials');
        }

        // Set session with user_id
        setSession(matchedUser.serviceToken, matchedUser.user.email);
        dispatch({
            type: LOGIN,
            payload: { isLoggedIn: true, user: matchedUser.user }
        });
    };

    const logout = () => {
        setSession(null, null);
        dispatch({ type: LOGOUT });
        localStorage.removeItem('isLoggedIn');
    };

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;

// import PropTypes from 'prop-types';
// import { createContext, useEffect, useReducer } from 'react';

// // third-party
// import { Chance } from 'chance';
// import jwtDecode from 'jwt-decode';

// // reducer - state management
// import { LOGIN, LOGOUT } from 'store/actions';
// import accountReducer from 'store/accountReducer';

// // project imports
// import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';

// const chance = new Chance();

// // constant
// const initialState = {
//     isLoggedIn: false,
//     isInitialized: false,
//     user: null
// };

// const verifyToken = (serviceToken) => {
//     if (!serviceToken) {
//         return false;
//     }
//     const decoded = jwtDecode(serviceToken);
//     /**
//      * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
//      */
//     return decoded.exp > Date.now() / 1000;
// };

// const setSession = (serviceToken) => {
//     if (serviceToken) {
//         localStorage.setItem('serviceToken', serviceToken);
//         axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
//         axios.defaults.headers.common.user_id = 1;
//     } else {
//         localStorage.removeItem('serviceToken');
//         delete axios.defaults.headers.common.Authorization;
//         delete axios.defaults.headers.common.user_id;
//     }
// };

// // ==============================|| JWT CONTEXT & PROVIDER ||============================== //
// const JWTContext = createContext(null);

// export const JWTProvider = ({ children }) => {
//     const [state, dispatch] = useReducer(accountReducer, initialState);

//     useEffect(() => {
//         const init = async () => {
//             try {
//                 const serviceToken = window.localStorage.getItem('serviceToken');
//                 if (serviceToken && verifyToken(serviceToken)) {
//                     setSession(serviceToken);
//                     const response = await axios.get('/api/account/me');
//                     const { user } = response.data;
//                     dispatch({
//                         type: LOGIN,
//                         payload: {
//                             isLoggedIn: true,
//                             user
//                         }
//                     });
//                 } else {
//                     dispatch({
//                         type: LOGOUT
//                     });
//                 }
//             } catch (err) {
//                 console.error(err);
//                 dispatch({
//                     type: LOGOUT
//                 });
//             }
//         };

//         init();
//     }, []);

//     const login = async (email, password) => {
//         const response = await axios.post('/api/account/login', { email, password });
//         const { serviceToken, user } = response.data;
//         setSession(serviceToken);
//         dispatch({
//             type: LOGIN,
//             payload: {
//                 isLoggedIn: true,
//                 user
//             }
//         });
//     };

//     const register = async (email, password, firstName, lastName) => {
//         // todo: this flow need to be recode as it not verified
//         const id = chance.bb_pin();
//         const response = await axios.post('/api/account/register', {
//             id,
//             email,
//             password,
//             firstName,
//             lastName
//         });
//         let users = response.data;

//         if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
//             const localUsers = window.localStorage.getItem('users');
//             users = [
//                 ...JSON.parse(localUsers),
//                 {
//                     id,
//                     email,
//                     password,
//                     name: `${firstName} ${lastName}`
//                 }
//             ];
//         }

//         window.localStorage.setItem('users', JSON.stringify(users));
//     };

//     const logout = () => {
//         setSession(null);
//         dispatch({ type: LOGOUT });
//     };

//     const resetPassword = async (email) => {
//         console.log(email);
//     };

//     const updateProfile = () => {};

//     if (state.isInitialized !== undefined && !state.isInitialized) {
//         return <Loader />;
//     }

//     return (
//         <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
//     );
// };

// JWTProvider.propTypes = {
//     children: PropTypes.node
// };

// export default JWTContext;
