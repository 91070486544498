const statuses = {
  INITIALIZED: "Pending",
  QUEUED: "Queued for Processing",
  "IN-PROGRESS": "Processing",
  COMPLETED: "Complete",
  FAILED: "Error",
  AWAITING_REVIEW: "Awaiting Review",
};

export { statuses };
