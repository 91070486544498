import React from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircleOutline } from "react-icons/io";
const VideoVelocityTypePopup = ({pdfId, toggleOverlay}) => {
  const navigate = useNavigate();

  const handleGoHome = async (generateType = "short") => {
    // toggleOverlay();
    navigate("/annotate-video/"+pdfId+"/"+generateType);
  };
  return (
    <div className="overlay flex justify-center items-center ">
      <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-5 rounded relative">
      <div
          className="absolute top-1 right-1 cursor-pointer"
          onClick={e=>toggleOverlay()}
        >
          <IoMdCloseCircleOutline />
        </div>
      <h3 className="text-center font-bold text-2xl mb-3 mt-2">
        Generate Video
        </h3>
        <p>
        What would you like to generate?
        </p>
        <Button onClick={()=>{handleGoHome('short')}}>Short Video</Button>
        
        <Button onClick={()=>{handleGoHome('long')}}>Long Video</Button>
      </div>
    </div>
  );
};

export { VideoVelocityTypePopup };
