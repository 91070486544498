import React, { useState } from "react";
import { Button, UploadPdfOverlay } from "../../components";

const UploadPage = () => {
  const [showUploadOverlay, setShowUploadOverlay] = useState(true);

  const toggleUploadOverlay = () => {
    setShowUploadOverlay((prev) => !prev);
  };
  return (
    <div className="flex justify-center items-center w-[100vw] h-[100vh] ">
      {showUploadOverlay && (
        <UploadPdfOverlay toggleUploadOverlay={toggleUploadOverlay} />
      )}
      <div className="bg-[#f0f0f0] w-[95vw] h-[95vh] flex border border-1">
        <div className="w-[50%] flex flex-col justify-center items-start gap-6 px-[200px]">
          <h2 className="text-3xl font-bold">Upload Files</h2>
          <p className="leading-6	">
            Effortlessly upload and process multiple documents, track progress &
            receive real-time updates on document status.
          </p>
          <Button onClick={toggleUploadOverlay}>Upload</Button>
        </div>
        <div className="bg-white w-[50%]">
          <img
            src={process.env.PUBLIC_URL + "/upload_illustration.svg"}
            className="w-[90%] h-[90%]"
            alt="upload"
          />
        </div>
      </div>
    </div>
  );
};

export { UploadPage };
