import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useParams } from "react-router-dom";
import { getOutputDetails } from "../../utils";
import toast from "react-hot-toast";
import { IoArrowDownCircleOutline } from "react-icons/io5";


const DeckOutputPage = () => {
  const [outputData, setOutputData] = useState({});
  const { outputId } = useParams();
  useEffect(() => {
    (async () => {
      try {
        const res = await getOutputDetails(outputId);
        setOutputData(res);
        document.title = `${res?.title} | ${res?.type}`;
      } catch (e) {
        setOutputData({});
        toast.error("Something went wrong");
      }
    })();
  }, [outputId]);

  const handleDownload  = async (fileUrl, title) => {
    try {
      console.log(title)
      const response = await fetch(fileUrl, { mode: "cors" }); // Ensure CORS is allowed
      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = url;
      link.download = `${title.trim()}.pptx`; // Set your desired file name here
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  return (
    <div>
    <div className="flex flex-col gap-5 items-center pt-5">
      <p className="font-bold text-xl">{outputData?.title}</p>
      <DocViewer
        documents={[
          {
            uri: outputData?.url,
            fileType: "pptx",
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
          },
        }}
        style={{ height: "85vh", width: "100%" }}
      />
      </div>
      <div class="flex justify-end" style={{
          color: '#fff',
          marginTop: '-23px',
          marginRight: '90px',
      }}>
      <IoArrowDownCircleOutline className="pointer-events-auto" onClick={e=>handleDownload(outputData?.url, outputData?.title)} />
      </div>
      {/* {outputData.url && (
        <iframe
          src={`https://docs.google.com/viewer?embedded=true&url=${outputData?.url}`}
          style={{ height: "85vh", width: "100%" }}
        ></iframe>
      )} */}
    </div>
  );
};

export { DeckOutputPage };
