import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, OutputList } from "../../components";

const OutputsPage = () => {
  const { resourceId } = useParams();
  const navigate = useNavigate();
  console.log(resourceId);
  useEffect(() => {
    document.title = `Outputs`;
  },[])
  return (
    <div className="mx-3">
      <h1 className="my-3 text-center font-bold text-3xl">Outputs</h1>
      <OutputList resourceId={resourceId} />
      <div className="w-full my-5 mx-auto text-center">
        <Button onClick={() => navigate("/")}>Go back</Button>
      </div>
    </div>
  );
};

export { OutputsPage };
