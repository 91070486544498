import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ChatInput } from "../ChatInput/ChatInput";
import { ContextBar } from "../ContextBar/ContextBar";
import { Loader } from "../Loader/Loader";
import { FaqQuestion } from "../FaqQuestion/FaqQuestion";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { resetHistory } from "../../features";

const Chat = ({ resourceId }) => {
  const chatContainerRef = useRef(null);

  const { playground } = useSelector((state) => state);
  const { isInRecordMode } = playground;

  const dispatch = useDispatch();

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      const scrollHeight = chatContainer.scrollHeight;
      const clientHeight = chatContainer.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      const currentScrollTop = chatContainer.scrollTop;

      // Calculate distance to scroll
      const distance = maxScrollTop - currentScrollTop;

      // Set up the scroll animation
      const duration = 300; // Adjust duration as needed
      let start;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const elapsed = timestamp - start;
        const progress = Math.min(elapsed / duration, 1);
        chatContainer.scrollTop = currentScrollTop + distance * progress;

        if (elapsed < duration) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    }
  };

  const chatState = useSelector((state) => state?.chat);
  const { history: chatHistory, selectedContext, showLoader } = chatState;

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    // remove history on first-render (to avoid showing same chat history across files)
    dispatch(resetHistory());
  }, []);
  return (
    <div className="flex flex-col justify-between w-3/12 bg-black/0 rounded-lg border-2 border-dashed border-slate-800/60 relative overflow-y-none p-2 py-4">
      <div
        class="messages flex flex-col h-[89%] justify-start overflow-y-scroll no-scrollbar"
        ref={chatContainerRef}
      >
        <p className="text-center text-2xl font-bold sticky top-0 bg-white pb-[15px]">
          Explore Document
        </p>
        {chatHistory?.map((ele) =>
          ele.sender === "bot" ? (
            ele?.type === "faq" ? (
              <div className="mx-1 mt-1 mb-2 py-3 px-4 bg-[#ebf4fc] text-slate-50 rounded">
                {ele?.faqs?.map((faq) => (
                  <FaqQuestion
                    question={faq?.question}
                    answer={faq?.answer}
                    isInRecordMode={isInRecordMode}
                    scrollToBottom={scrollToBottom}
                  />
                ))}
              </div>
            ) : (
              <div
                className={
                  "mx-1 mt-1 mb-2 py-3 px-4 bg-[#ebf4fc] text-slate-50 rounded "
                }
              >
                <ReactMarkdown
                  className={"prose " + (isInRecordMode ? "text-lg" : "")}
                >
                  {ele.message}
                </ReactMarkdown>
              </div>
            )
          ) : (
            <div className="mx-1 mt-2 py-3 px-4 bg-[#0041c2] text-slate-50 rounded">
              <p
                className={
                  "overflow-hidden	whitespace-nowrap1	text-ellipsis " +
                  (isInRecordMode ? "text-lg" : "")
                }
              >
                {ele.message}
              </p>
            </div>
          )
        )}
        {showLoader && <Loader />}
      </div>
      {selectedContext && (
        <div className="absolute left-4 right-4 bottom-[10%]">
          <ContextBar text={selectedContext} isInRecordMode={isInRecordMode} />
        </div>
      )}
      <div className="absolute left-4 right-4 bottom-[0%]">
        <ChatInput resourceId={resourceId} isInRecordMode={isInRecordMode} />
      </div>
    </div>
  );
};

export { Chat };
